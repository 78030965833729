import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Model } from "./Buster_drone";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Canvas
      style={{
        position: "absolute",
        top: 0,
        zIndex: 1,
      }}
      shadows
      width={window.innerWidth}
      camera={{
        position: [-5, 5, 10],
        fov: 10,
      }}
    >
      <Suspense fallback={null}>
        <OrbitControls autoRotate autoRotateSpeed={0.05} enableZoom={false} />
        <ambientLight intensity={1.5} />
        <spotLight
          intensity={0.5}
          angle={0.1}
          penumbra={1}
          position={[10, 15, -5]}
          castShadow
        />
        <Model />
      </Suspense>
    </Canvas>
    <div
      id="title"
      style={{
        width: "100%",
        backgroundColor: "#eee",
        display: "flex",
        height: "100%",
        padding: 150,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "auto",
      }}
    >
      <p
        style={{
          fontSize: 24,
          color: "",
          fontWeight: 300,
          fontFamily: "Space Grotesk",
          textShadow: "1.5px 2px 10px #f0f0f0",
        }}
      >
        Hi I'm Jan Falih Fadhillah, a programmer claimed by myself based on
        Jakarta, Indonesia.
      </p>
      <h1
        style={{
          position: "relative",
          fontSize: 78,
          width: "100%",
          fontFamily: "Space Grotesk",
          fontWeight: 700,
          textAlign: "start",
          color: "#222",
          textShadow: "1.5px 2px 20px #f0f0f0",
        }}
      >
        MORE THAN JUST ✨
        <br />A{" "}
        <span
          style={{
            color: "#fff461",
            textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
          }}
        >
          TALENTED
        </span>{" "}
        PROGRAMMER 💻
      </h1>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
